'use client';

import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import { ButtonTypesEnum } from '~/types-and-enums/generalTypes';
import { colors, spacing } from '~/utils/tailwindUtils';

type ButtonProps = {
  buttonStyle?: ButtonTypesEnum | string;
  text?: string | React.ReactNode;
  buttonClassName?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right' | 'top' | 'bottom';
  iconClassName?: string;
  iconColor?: string;
  textClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  href?: string;
  rounded?: boolean;
  loadingDuration?: number;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

const _buttonClassName: Record<string | ButtonTypesEnum, string> =
  Object.freeze({
    [ButtonTypesEnum.primary]:
      'bg-secondary hover:bg-red-600 md:hover:opacity-90 md:hover:shadow-md',
    [ButtonTypesEnum.default]:
      'bg-gray-50 border border border-gray-900 md:hover:bg-gray-100 md:hover:shadow-sm',
    [ButtonTypesEnum.disabled]: 'bg-primary',
    [ButtonTypesEnum.noStyle]: '',
  });
const _textClassName: Record<string | ButtonTypesEnum, string> = Object.freeze({
  [ButtonTypesEnum.primary]: 'text-white',
  [ButtonTypesEnum.default]: 'text-gray-900',
  [ButtonTypesEnum.disabled]: 'text-gray-900',
});
const _iconColorName: Record<string | ButtonTypesEnum, string> = Object.freeze({
  [ButtonTypesEnum.primary]: colors.white,
  [ButtonTypesEnum.default]: colors.gray[900],
  [ButtonTypesEnum.disabled]: colors.gray[900],
});

/**
 * A customizable button component that can render as an anchor or a button.
 * It supports various props for styling, icon positioning, and loading state.
 *
 * @param {string} text - The text content of the button.
 * @param {React.ReactNode} icon - The icon to be displayed alongside the text.
 * @param {string} iconClassName - The class name for the icon container.
 * @param {'left' | 'right' | 'top' | 'bottom'} iconPosition - The position of the icon relative to the text.
 * @param {string} iconColor - The color of the icon.
 * @param {string} buttonClassName - The class name for the button container.
 * @param {string} textClassName - The class name for the text container.
 * @param {boolean} disabled - Whether the button is disabled.
 * @param {boolean} isLoading - Whether the button is in a loading state.
 * @param {string} href - The link URL if the button should render as an anchor.
 * @param {object} props - Additional props to be passed to the button or anchor element.
 * @return {JSX.Element} The rendered button or anchor element.
 */
const Button = ({
  buttonStyle = ButtonTypesEnum.primary,
  text,
  icon,
  iconClassName,
  iconPosition = 'left',
  iconColor = colors.white,
  buttonClassName,
  textClassName,
  disabled,
  isLoading,
  href,
  rounded,
  loadingDuration = 3000,
  ...props
}: ButtonProps) => {
  const sharedClassNames = twMerge(
    'flex items-center min-w-fit justify-center px-3 py-1.5 gap-2 rounded-lg w-full cursor-pointer transition-colors duration-200',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500',
    ['top', 'bottom'].includes(iconPosition) && 'flex-col',
    buttonStyle && _buttonClassName[buttonStyle],
    disabled && 'opacity-50 cursor-not-allowed hover:opacity-50',
    rounded && 'rounded-full',
    buttonClassName
  );

  // Experimental: State to track if the link was clicked
  const [linkClicked, setLinkClicked] = useState(false);

  useEffect(() => {
    // Effect to reset linkClicked after 3 seconds
    if (linkClicked) {
      const timer = setTimeout(() => {
        setLinkClicked(false);
      }, loadingDuration); // Reset after 3 seconds

      // Cleanup function to clear the timer if component unmounts or linkClicked changes
      return () => clearTimeout(timer);
    }
  }, [linkClicked, loadingDuration]);

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (e?.ctrlKey && e?.button === 0) return;
      setLinkClicked(true);
    },
    []
  );

  const content = (
    <>
      {(isLoading || linkClicked) && (
        <Icon
          name={IconName.Loader}
          size={spacing[4]}
          color={_iconColorName[buttonStyle] || iconColor}
          className={twMerge('animate-spin', iconClassName)}
          data-testid="loading-spinner"
        />
      )}
      {icon && ['left', 'top'].includes(iconPosition) && (
        <div className={iconClassName}>{icon}</div>
      )}
      {text && (
        <div
          className={twMerge(
            'font-semibold text-sm',
            _textClassName[buttonStyle],
            textClassName
          )}
        >
          {text}
        </div>
      )}
      {icon && ['right', 'bottom'].includes(iconPosition) && (
        <div className={iconClassName}>{icon}</div>
      )}
    </>
  );

  // Render an anchor if href is provided, otherwise render a button
  if (href) {
    return (
      <Link
        href={href}
        className={sharedClassNames}
        data-testid="link-button"
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
        onClick={(e) => {
          handleLinkClick(e);
          props.onClick && props.onClick(e);
        }}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      className={sharedClassNames}
      data-testid="button"
      disabled={disabled}
      {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {content}
    </button>
  );
};

export default Button;
