'use client';

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import { colors, spacing } from '~/utils/tailwindUtils';

type SelectOption = {
  id: string | number;
  value: string | number;
  label?: string;
};

type SimpleSelectProps = {
  options: SelectOption[];
  value: string | number | undefined;
  onChange: (newValue: string | null) => void;
  name?: string;
  placeholder: string;
  emptyLabel: string;
  selectContainerClassName?: string;
  selectClassName?: string;
};

/**
 * SimpleSelect component that allows the user to select an option from a list
 * @param options
 * @param value
 * @param onChange
 * @param name
 * @param placeholder
 * @param emptyLabel
 * @param selectClassName
 * @returns SimpleSelect component
 */
const SimpleSelect = ({
  options,
  value,
  onChange,
  name,
  placeholder,
  emptyLabel,
  selectContainerClassName,
  selectClassName,
}: SimpleSelectProps) => {
  const [label, setLabel] = useState<string | null>(
    options.find((option) => option.value === value)?.label || null
  );

  const handleChange = (newValue: string) => {
    onChange(newValue);
    const selectedOption = options.find((option) => option.value === newValue);
    setLabel(selectedOption?.label || null);
  };

  useEffect(() => {
    setLabel(options.find((option) => option.value === value)?.label || null);
  }, [value, options]);

  return (
    <Listbox name={name} value={value} onChange={handleChange}>
      <div className="relative w-full">
        <ListboxButton
          id={name}
          className={twMerge(
            'relative w-full cursor-default rounded-md bg-gray-50 h-10 py-1.5 pl-3 pr-10 text-left text-gray-950 shadow-sm ring-inset ring-0 focus:outline-none focus:ring-1 focus:ring-gray-300 sm:text-sm sm:leading-6',
            selectContainerClassName
          )}
        >
          {placeholder && !value && (
            <span className="block truncate text-gray-400">{placeholder}</span>
          )}
          {value && (
            <span className="block truncate text-xs md:text-sm">{label}</span>
          )}
          <span className="pointer-events-none absolute inset-y-0 right-1 flex items-center pr-2">
            <Icon
              size={spacing[4]}
              name={IconName.ChevronDown}
              color={colors.gray[600]}
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-100 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {options.length === 0 ? (
            <ListboxOption
              value={''}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-300 data-[focus]:text-white"
            >
              {emptyLabel}
            </ListboxOption>
          ) : (
            options.map((item) => (
              <ListboxOption
                key={item.id}
                value={item.value}
                className={twMerge(
                  'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-200 ',
                  selectClassName
                )}
              >
                <span className="block truncate text-xs font-normal group-data-[selected]:font-semibold md:text-sm">
                  {item.label}
                </span>
              </ListboxOption>
            ))
          )}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

export default SimpleSelect;
